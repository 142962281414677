
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$columns-col-gap: $spacer !default;

// desktop
$columns-breakpoint: $desktop-breakpoint !default;

.columns {
  > div {
    + div {
      margin-top: $columns-col-gap;
    }
  }

  @include media-breakpoint-up($columns-breakpoint) {
    > div {
      + div {
        margin-top: 0;
      }
    }
  }
}
