
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$infocontainer-gap: 3px !default;
$infocontainer-padding-y: $spacer * 1.25 !default;
$infocontainer-padding-x: $infocontainer-padding-y !default;
$infocontainer-bg: $beige !default;
$infocontainer-border-radius: $border-radius !default;

$infocontainer-hr-margin-y: $spacer * 0.5 !default;

.info-container  {
  padding: $infocontainer-padding-y $infocontainer-padding-x;
  background-color: $infocontainer-bg;
  border-radius: $infocontainer-border-radius;

  > hr {
    margin-top: $infocontainer-hr-margin-y;
    margin-bottom: $infocontainer-hr-margin-y
    ;
  }

  + .info-container {
    margin-top: $infocontainer-gap;
  }
}
