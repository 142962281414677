
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$instagramfeed-padding-y: $component-padding-y !default;
$instagramfeed-padding-x: 0 !default;

$instagramfeed-img-gap: $spacer !default;

$instagramfeed-header-font-size: inherit !default;
$instagramfeed-header-font-weight: inherit !default;
$instagramfeed-header-color: inherit !default;
$instagramfeed-header-links-font-weight: $font-weight-medium !default;

$instagramfeed-mosaic-header-font-size: $h5-font-size !default;
$instagramfeed-mosaic-header-font-weight: $font-weight-medium !default;
$instagramfeed-mosaic-header-color: $dark !default;

$instagramfeed-mosaic-header-links-font-weight: $font-weight-medium !default;

$instagramfeed-mosaic-tiles-gap: $spacer !default;

// tablet
$instagramfeed-tablet: $tablet-breakpoint !default;

// desktop
$instagramfeed-desktop: $desktop-breakpoint !default;

$instagramfeed-desktop-padding-y: $component-desktop-padding-y !default;
$instagramfeed-desktop-padding-x: $component-desktop-padding-x !default;

$instagramfeed-desktop-img-gap: $instagramfeed-img-gap !default;

$instagramfeed-desktop-mosaic-tiles-gap: $spacer * 2 !default;

.instagram-feed {
  margin: $instagramfeed-padding-y $instagramfeed-padding-x;

  .instagram-feed-header {
    @include indented-textelements();

    p {
      font-size: $instagramfeed-header-font-size;
      font-weight: $instagramfeed-header-font-weight;
      color: $instagramfeed-header-color;

      a {
        font-weight: $instagramfeed-header-links-font-weight;
      }
    }
  }

  .instagram-feed-tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: stretch;
    align-items: center;
    margin: $instagramfeed-img-gap * -0.5;

    .instagram-feed-tile {
      display: block;
      margin: 0;
      padding: $instagramfeed-img-gap * 0.5;
      width: 50%;

      a {
        display: block;

        img {
          aspect-ratio: 1 / 1;
          object-fit: cover;
        }
      }
    }
  }

  &.is-mosaic {
    .instagram-feed-header {
      p {
        font-size: $instagramfeed-mosaic-header-font-size;
        font-weight: $instagramfeed-mosaic-header-font-weight;
        color: $instagramfeed-mosaic-header-color;

        a {
          font-weight: $instagramfeed-mosaic-header-links-font-weight;
        }
      }
    }

    .instagram-feed-tiles {
      margin-top: $instagramfeed-mosaic-tiles-gap;
    }
  }

  @include media-breakpoint-up($instagramfeed-tablet) {
    .instagram-feed-tiles {
      .instagram-feed-tile {
        width: 25%;
      }
    }

    &.is-mosaic {
      .instagram-feed-header {
        text-align: center;
      }

      .instagram-feed-tiles {
        justify-content: center;
        align-items: flex-start;

        .instagram-feed-tile {
          width: 25%;

          &:nth-child(-n+3) {
            margin-top: 5%;
          }

          &:first-child {
            margin-top: 0;
            margin-left: 10%;
            width: 40%;
            transform: none;
          }

          &:last-child {
            margin-top: -10%;
            margin-right: 10%;
            width: 40%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up($instagramfeed-desktop) {
    margin: $instagramfeed-desktop-padding-y $instagramfeed-desktop-padding-x;

    .instagram-feed-tiles {
      margin: $instagramfeed-desktop-img-gap * -0.5;

      .instagram-feed-tile {
        padding: $instagramfeed-desktop-img-gap * 0.5;
      }
    }

    &.is-mosaic {
      .instagram-feed-tiles {
        margin-top: $instagramfeed-desktop-mosaic-tiles-gap - $instagramfeed-desktop-img-gap * -0.5;
      }
    }
  }
}
