
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$iconbutton-breakpoint: $desktop-breakpoint !default;

.icon-button {
  @include media-breakpoint-down(get-breakpoint-key($iconbutton-breakpoint, -1)) {
    padding-top: 0;
    padding-bottom: 0;
    background: none!important;
    border: 0 none!important;
    color: inherit!important;

    .btn-text {
      display: none;
    }
  }

  @include media-breakpoint-up($iconbutton-breakpoint) {
    .btn-text {
      display: block;
    }

    .btn-icon {
      display: none;
    }
  }
}
