
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$content-margin-y: $component-padding-y !default;
$content-margin-x: 0 !default;

$content-bg-offset-y: $component-padding-y !default;
$content-bg-offset-x: $component-padding-x !default;

$content-text-align: left !default;

// desktop
$content-breakpoint: $desktop-breakpoint !default;

$content-desktop-margin-y: $component-desktop-padding-y !default;
$content-desktop-margin-x: 0 !default;

$content-desktop-bg-offset-y: $component-desktop-padding-y !default;
$content-desktop-bg-offset-x: $component-desktop-padding-x !default;

$content-desktop-text-align: left !default;

.content {
  position: relative;
  margin: $content-margin-y $content-margin-x;

  @each $key, $value in $pastel-colors {
    &.bg-#{$key} {
      background-color: $value;
    }
  }

  &[class*="bg-pastel-"] {
    margin-left: $content-bg-offset-x * -1;
    margin-right: $content-bg-offset-x * -1;
    padding: $content-bg-offset-y $content-bg-offset-x;

    + .content {
      &[class*="bg-pastel-"] {
        margin-top: $content-margin-y * -1;
      }
    }
  }

  @include media-breakpoint-up($content-breakpoint) {
    margin: $content-desktop-margin-y $content-desktop-margin-x;

    @each $key, $value in $pastel-colors {
      &.bg-#{$key} {
        margin-left: 0;
        margin-right: 0;
        padding: $content-desktop-bg-offset-y $content-desktop-bg-offset-x;
      }
    }

    &[class*="bg-pastel-"] {
      + .content {
        &[class*="bg-pastel-"] {
          margin-top: $content-desktop-margin-y * -1;
        }
      }
    }
  }
}
