
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$breadcrumb-backlink-gap: $spacer * 0.25 !default;
$breadcrumb-backlink-font-size: $breadcrumb-font-size !default;
$breadcrumb-backlink-color: $gray-700 !default;
$breadcrumb-backlink-icon-color: $breadcrumb-backlink-color !default;
$breadcrumb-backlink-icon: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' fill='#{$breadcrumb-backlink-icon-color}' width='10' height='10' viewBox='0 0 16 16'><path d='M4.4,8l6.4-6.4c0.2-0.2,0.2-0.5,0-0.6s-0.5-0.2-0.6,0L3.7,7.4C3.5,7.5,3.5,7.8,3.5,8c0,0.2,0.1,0.5,0.3,0.6l6.4,6.4c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1c0.2-0.2,0.2-0.5,0-0.6L4.4,8z'/></svg>") !default;
$breadcrumb-backlink-seperator-gap: $breadcrumb-item-padding * 0.5 !default;
$breadcrumb-backlink-seperator-border: set-nth(map-get($borders, 'base'), 3, $gray-700) !default;

$breadcrumb-padding-y: $breadcrumb-padding-y !default;
$breadcrumb-padding-x: $breadcrumb-padding-x !default;
$breadcrumb-item-gap: $breadcrumb-item-padding * 0.5 !default;
$breadcrumb-item-font-size: $breadcrumb-font-size !default;
$breadcrumb-item-color: $gray-700 !default;
$breadcrumb-item-active-color: $gray-700 !default;

// desktop
$breadcrumb-breakpoint: $desktop-breakpoint !default;

$breadcrumb-desktop-backlink-gap: $breadcrumb-backlink-gap !default;
$breadcrumb-desktop-backlink-seperator-gap: $breadcrumb-item-padding !default;

$breadcrumb-desktop-padding-y: $spacer * 1.5 !default;
$breadcrumb-desktop-padding-x: 0 !default;
$breadcrumb-desktop-item-gap: $breadcrumb-item-padding !default;

.breadcrumb-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .breadcrumb-backlink {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    font-size: $breadcrumb-backlink-font-size;
    color: $breadcrumb-backlink-color;

    &:before {
      display: block;
      padding-right: $breadcrumb-backlink-gap;
      color: $breadcrumb-backlink-icon-color;
      content: escape-svg($breadcrumb-backlink-icon);
    }

    &:after {
      display: block;
      margin-left: $breadcrumb-backlink-seperator-gap;
      margin-right: $breadcrumb-backlink-seperator-gap;
      content: '';
      border-left: $breadcrumb-backlink-seperator-border;
    }
  }

  .breadcrumb {
    display: inline-flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    padding: $breadcrumb-padding-y $breadcrumb-padding-x;

    .breadcrumb-item {
      display: flex;
      font-size: $breadcrumb-item-font-size;
      color: $breadcrumb-item-color;

      &:before {
        float: none;
      }

      > a,
      > span {
        color: inherit;
        white-space: nowrap;
      }

      &.active {
        color: $breadcrumb-item-active-color;
      }

      + .breadcrumb-item {
        padding-left: $breadcrumb-item-gap;

        &:before {
          padding-right: $breadcrumb-item-gap;
        }
      }
    }
  }

  @include media-breakpoint-up($breadcrumb-breakpoint) {
    .breadcrumb-backlink {
      &:before {
        padding-right: $breadcrumb-desktop-backlink-gap;
      }

      &:after {
        margin-left: $breadcrumb-desktop-backlink-seperator-gap;
        margin-right: $breadcrumb-desktop-backlink-seperator-gap;
      }
    }

    .breadcrumb {
      padding: $breadcrumb-desktop-padding-y $breadcrumb-desktop-padding-x;

      .breadcrumb-item {
        + .breadcrumb-item {
          padding-left: $breadcrumb-desktop-item-gap;

          &:before {
            padding-right: $breadcrumb-desktop-item-gap;
          }
        }
      }
    }
  }
}
