
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$icontilegrid-title-gap: $spacer !default;

$icontilegrid-popularcategories-border: map-get($borders, 'base') !default;

$icontilegrid-tablet: $tablet-breakpoint !default;

.icon-tile-grid {
  @include component('y');

  .grid-title {
    margin-bottom: $icontilegrid-title-gap;
  }

  &.popularcategories {
    .row {
      .col {
        margin-bottom: -1px;
        margin-right: -1px;
        border: $icontilegrid-popularcategories-border;
      }
    }

    &.tiles-count-1 {
      .row {
        @include media-breakpoint-up($icontilegrid-tablet) {
          max-width: 50%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}
