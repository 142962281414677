
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$introbanner-margin-y: $component-padding-y !default;
$introbanner-margin-x: 0 !default;
$introbanner-pastel-colors: $pastel-colors !default;

$introbanner-content-padding-y: $component-padding-y !default;
$introbanner-content-padding-x: 0 !default;

$introbanner-content-header-img-gap: $paragraph-margin-bottom * 3 !default;

// desktop
$introbanner-breakpoint: $desktop-breakpoint !default;

$introbanner-desktop-margin-y: 0 !default;
$introbanner-desktop-margin-x: 0 !default;
$introbanner-desktop-bg-offset: 30% !default;

$introbanner-desktop-content-padding-y: $component-desktop-padding-y !default;
$introbanner-desktop-content-padding-x: 0 !default;

$introbanner-desktop-content-header-img-gap: $introbanner-content-header-img-gap !default;

.introbanner {
  @include fullwidth-bg();
  position: relative;
  margin: $introbanner-margin-y $introbanner-margin-x;

  &:first-child {
    margin-top: 0;
  }

  .introbanner-content {
    padding: $introbanner-content-padding-y $introbanner-content-padding-x;

    .backlink {
      margin-top: $introbanner-content-padding-y * -1;
    }

    .content-header {
      position: relative;
      @include indented-textelements();

      .header-image {
        position: relative;
        left: 50%;
        margin-top: $introbanner-content-header-img-gap;
        margin-bottom: $introbanner-content-header-img-gap;
        width: 100vw;
        transform: translate3d(-50%, 0, 0);

        img {
          display: block;
          width: 100%;
          max-width: none;
          height: auto;
        }
      }
    }

    .content-body {
      @include indented-textelements();
    }

    &.no-content {
      padding-bottom: 0;

      .content-header {
        .header-image {
          margin-bottom: 0;
        }
      }
    }
  }

  @each $key, $value in $introbanner-pastel-colors {
    &.bg-#{$key} {
      background-color: $value;
    }
  }

  @include media-breakpoint-up($introbanner-breakpoint) {
    margin: $introbanner-desktop-margin-y $introbanner-desktop-margin-x;

    &:before {
      bottom: $introbanner-desktop-bg-offset;
    }

    &:after {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: $introbanner-desktop-bg-offset;
      z-index: -1;
    }

    &:first-child {
      margin-top: 0;
    }

    .introbanner-content {
      padding: $introbanner-desktop-content-padding-y $introbanner-desktop-content-padding-x;

      .backlink {
        margin-top: $introbanner-desktop-content-padding-y * -1;
      }

      .content-header {
        .header-image {
          position: static;
          left: 0;
          margin-top: $introbanner-desktop-content-header-img-gap;
          margin-bottom: $introbanner-desktop-content-header-img-gap;
          width: auto;
          transform: none;
        }
      }
    }

    @each $key, $value in $introbanner-pastel-colors {
      &.bg-#{$key} {
        background-color: transparent;

        &:before,
        &:after {
          background-color: $value;
        }
      }
    }
  }
}
