
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$accordion-content-padding-y: $component-padding-y !default;
$accordion-content-padding-x: 0 !default;

.accordion-content {
  > div {
    padding: $accordion-content-padding-y $accordion-content-padding-x;

    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .accordion.is-shapeless &,
  &.is-shapeless {
    border-top: 0 none;

    > div  {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
