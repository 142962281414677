
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

// mobile
$contentblock-margin-y: $component-padding-y !default;
$contentblock-margin-x: 0 !default;

$contentblock-bg-offset-y: $component-padding-y !default;
$contentblock-bg-offset-x: $component-padding-x !default;

$contentblock-img-transition: opacity ease-out 750ms 250ms, transform ease-out 750ms 250ms !default;

$contentblock-text-align: left !default;

// desktop
$contentblock-breakpoint: $desktop-breakpoint !default;

$contentblock-desktop-margin-y: $component-desktop-padding-y !default;
$contentblock-desktop-margin-x: 0 !default;

$contentblock-desktop-bg-offset-y: $component-desktop-padding-y !default;
$contentblock-desktop-bg-offset-x: $component-desktop-padding-x !default;

$contentblock-desktop-text-align: left !default;

.contentblock {
  position: relative;
  margin: $contentblock-margin-y $contentblock-margin-x;

  .contentblock-text {
    > img:only-child,
    > *:only-child > img {
      display: block;
      margin: 0;
      width: 100%;
      max-width: none;
      height: auto;
      opacity: 0;
      transform: translate3d(0, 5%, 0);
      transition: $contentblock-img-transition;
    }
  }

  .contentblock-text {
    text-align: $contentblock-text-align;
  }

  @each $key, $value in $pastel-colors {
    &.bg-#{$key} {
      background-color: $value;
    }
  }

  &[class*='bg-pastel-'] {
    margin-left: $contentblock-bg-offset-x * -1;
    margin-right: $contentblock-bg-offset-x * -1;
    padding: $contentblock-bg-offset-y $contentblock-bg-offset-x;

    + .contentblock {
      &[class*='bg-pastel-'] {
        margin-top: $contentblock-margin-y * -1;
      }
    }
  }

  &.above-the-fold {
    .contentblock-text {
      > img:only-child,
      > *:only-child > img {
        opacity: 1;
        transform: translate3d(0, 0%, 0);
      }
    }
  }

  &.narrow {
    &[class*='bg-pastel-'] {
      margin-left: 0;
      margin-right: 0;
      padding: 0;

      .row {
        > div {
          margin: 0;
        }
      }

      .contentblock-text {
        padding: $contentblock-bg-offset-y $contentblock-bg-offset-x;
      }
    }
  }

  @include media-breakpoint-up($contentblock-breakpoint) {
    margin: $contentblock-desktop-margin-y $contentblock-desktop-margin-x;

    .contentblock-text {
      text-align: $contentblock-desktop-text-align;
    }

    @each $key, $value in $pastel-colors {
      &.bg-#{$key} {
        margin-left: $contentblock-desktop-bg-offset-x * -1;
        margin-right: $contentblock-desktop-bg-offset-x * -1;
        padding: $contentblock-desktop-bg-offset-y $contentblock-desktop-bg-offset-x;
      }
    }

    &[class*='bg-pastel-'] {
      + .contentblock {
        &[class*='bg-pastel-'] {
          margin-top: $contentblock-desktop-margin-y * -1;
        }
      }
    }

    &.narrow {
      &[class*='bg-pastel-'] {
        margin-left: 0;
        margin-right: 0;
        padding: 0;

        .contentblock-text {
          padding-top: 0;
          padding-bottom: 0;

          > img:only-child,
          > *:only-child > img {
            margin-left: $contentblock-desktop-bg-offset-x * -0.5;
            margin-right: $contentblock-desktop-bg-offset-x * -0.5;
            width: calc(100% + $contentblock-desktop-bg-offset-x);
            max-width: calc(100% + $contentblock-desktop-bg-offset-x);
          }
        }
      }
    }
  }
}
