
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.anker {
  display: block;
  position: relative;
  visibility: hidden;
}
