
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

.dragger {
  width: 100%;

  &.is-draggable {
    overflow: hidden;
    cursor: grab;
    user-select: none;

    &.is-dragging {
      cursor: grabbing;

      * {
        cursor: inherit!important;
      }
    }
  }

  .is-touch & {
    overflow: auto;
    cursor: default;
    user-select: initial;
  }
}
