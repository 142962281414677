
          @use 'sass:math';
          $envname: 'c1';
          $envtype: production;
          @import '@/assets/css/core.scss';
        

$readmore-text-gap: $spacer;
$readmore-toggle-font-weight: $font-weight-medium;

.readmore {
  @include clearfix();

  .readmore-text {
    margin-right: $readmore-text-gap;
  }

  .readmore-toggle {
    display: inline-flex;
    flex-wrap: nowrap;
    align-items: center;
    font-weight: $readmore-toggle-font-weight;
  }
}
